<template>
    <div v-on-click-outside="() => toggleCalendar(false)" class="input-group" @click="toggleCalendar(true)">
        <label :for="`${name}DateInput`">
            <NuxtIcon :name="`ri:calendar-2-line`" class="input-icon-prepend" />

            <input
                :id="`${name}DateInput`"
                v-model="localeDate"
                type="text"
                class="cursor-pointer"
                :placeholder="placeholder"
                readonly
            />
        </label>

        <LazyFormDatePickerElement
            v-if="showDropdown"
            :value="dateRef"
            mode="date"
            expanded
            :name="name"
            color="primary"
            :anchor="anchor"
            :title="title"
            :min-date="new Date(minDate)"
            :max-date="new Date(new Date().setMonth(new Date().getMonth() + 12))"
            @toggle-calendar="toggleCalendar"
            @update:value="onUpdateDate"
        />
    </div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components';
import screens from '#tailwind-config/theme/screens';

const props: any = defineProps({
    name: { type: String, required: true, default: 'date' },
    placeholder: { type: String, required: false, default: 'Select Date' },
    value: { type: [String, Date], required: false, default: null },
    minDate: { type: [String, Date], required: false, default: null },
    direction: { type: String, required: true, default: 'from' },
    icon: { type: String, required: false, default: '' },
    items: { type: Array, required: false, default: null },
    title: { type: String, required: false, default: 'Select a Date' },
    anchor: { type: String, required: false, default: 'left' },
});

// Constant Variable for access to locale & translations
const { locales, locale } = useI18n();

// Constant variable for initialising emit events
const emit: any = defineEmits(['update:value']);

// Toggle to show/hide calendar.
const showDropdown = ref(false);

const columnCount = ref(2);

const localeObj: any = Object.values(locales.value).find((item: any) => item.code === locale.value);

// Set minDate to use locale, if required.
const minDateRef = computed(() => new Date(props.minDate));

const dateRef: any = ref(props.value);

// Whenever new date is set, we want to update locale date and emit the data
// to parent where it will set the new minimum date when required.
// We then close dropdown as it is no longer needed.
const date: any = computed({
    get() {
        return dateRef.value;
    },
    set(value) {
        if (value) {
            emit('update:value', value);
            dateRef.value = value;
        }
        toggleCalendar(false);
    },
});

// Locale date, so we can present the dates suitable to user's local time
const localeDate = computed(() => (date.value ? formatDate(new Date(date.value), localeObj.language) : null));

const onUpdateDate = (value) => {
    date.value = value;
    emit('update:value', date.value);
};

// Change column count from 1 (mobile) to 2 (desktop/tablet)
const onWidthChange = () =>
    parseInt(screens.md.replace('px', '')) < window.innerWidth ? (columnCount.value = 2) : (columnCount.value = 1);

// Watch if minimum date is changed and if its greater than current date,
// then set that date to the new minimum date.
watch(minDateRef, (newVal) => {
    if (new Date(newVal) > date.value) date.value = new Date(newVal);
});

watch(
    () => props.value,
    (newVal) => {
        dateRef.value = newVal;
    }
);

if (!import.meta.server) {
    onMounted(() => {
        // Initialize Column Count
        onWidthChange();

        // Watch for column count
        window.addEventListener('resize', onWidthChange);
    });
    onUnmounted(() => window.removeEventListener('resize', onWidthChange));
}

function toggleCalendar(val: boolean) {
    if (val) {
        document.body.classList.add('overflow-hidden', 'md:overflow-auto', 'min-h-screen');
        showDropdown.value = true;
    } else {
        document.body.classList.remove('overflow-hidden', 'md:overflow-auto', 'min-h-screen');
        setTimeout(() => {
            showDropdown.value = false;
        }, 200);
    }
}

function formatDate(date: Date, locale: string): string {
    // Mapping for locales that should behave as other locales
    const localeMap: Record<string, string> = {
        'nl-be': 'nl', // Belgian Dutch should use 'nl' formatting (DD-MM-YYYY)
        'fr-be': 'nl', // Belgian French should use 'nl' formatting (DD-MM-YYYY)
        'en-za': 'en-GB', // South African English should use en-GB formatting (DD/MM/YYYY)
        'de-at': 'en-GB', // Austrian German should use en-GB formatting (DD/MM/YYYY)
        'jp-jp': 'ja-JP', // Japanese should use ja-JP formatting (YYYY/MM/DD)
        'pl-pl': 'en-CA', // Polish should use en-CA formatting (YYYY-MM-DD)
    };
    // Locales that should use one digit format for month
    const oneDigitFormatLocales = new Set(['en-US']);

    const mappedLocale = localeMap[locale.toLowerCase()] || locale;

    // Generate the formatted date string using the mapped locale
    return date.toLocaleDateString(mappedLocale, {
        year: 'numeric',
        month: oneDigitFormatLocales.has(mappedLocale) ? 'numeric' : '2-digit',
        day: '2-digit',
    });
}
</script>

<style lang="postcss" scoped>
.input-group :deep(.vc-container) {
    @apply fixed md:absolute md:top-12 bottom-0 md:bottom-auto left-0 right-0 z-[99] min-w-full max-w-[100vw] md:min-w-0 rounded border-[#e9daf0] border-b-[20px] flex flex-col;

    &:before {
        content: attr(data-title);
        @apply py-2 px-4 bg-primary rounded-t text-white font-bold text-lg;
    }
}

@media screen(md) {
    .input-group {
        &:deep(.vc-container.right) {
            @apply left-auto right-0;
        }
        &:deep(.vc-container.left) {
            @apply left-0 right-auto;
        }
    }
}

.input-group :deep(.vc-pane-container) {
    @apply relative;
}

.input-group :deep(.vc-container .vc-pane) {
    @apply min-w-[254px];
}

.input-group :deep(.vc-container .vc-header .vc-title) {
    @apply bg-transparent text-primary normal-case text-base;
}

.input-group :deep(.vc-container .vc-header .vc-arrow) {
    @apply w-10;
}

.input-group :deep(.vc-container .vc-header .vc-arrow) {
    @apply bg-primary text-white;
}

.input-group :deep(.vc-container .vc-header .vc-arrow:disabled) {
    @apply opacity-30;
}

.input-group :deep(.vc-container .vc-weekday) {
    @apply border-l border-l-gray-100 rounded-none text-black;
}

.input-group :deep(.vc-container .vc-day-content) {
    @apply w-full h-full text-primary border-l border-l-gray-100 rounded-none;
}

.input-group :deep(.vc-container .vc-day-content:hover) {
    @apply rounded-none text-amber-500;
}

.input-group :deep(.vc-container .vc-day-content.vc-disabled) {
    @apply opacity-30;
}

.input-group :deep(.vc-container .is-not-in-month .vc-day-content) {
    @apply opacity-0;
}

.input-group :deep(.vc-container .vc-highlights) {
    @apply z-[1];
}

.input-group :deep(.vc-container .vc-day-content.vc-highlight-content-solid) {
    @apply text-amber-500;
}

.input-group :deep(.vc-container .vc-highlight.vc-highlight-bg-solid) {
    @apply w-full h-full bg-transparent text-primary rounded-none border border-amber-500 z-[1];
}

.input-group label {
    width: 100%;
}

.input-group :deep(.vc-pane-layout) {
    gap: 40px;
}
</style>
